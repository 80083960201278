<template>
  <DetailForm persistent="toggle_table_panel">
    <DetailFormTable />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormTable from "@/components/control-sidebar/property-editors/detail-form-table.vue";
export default {
  name: "TableForm",
  components: {
    DetailForm,
    DetailFormTable
  }
};
</script>
